import './text2SignLang.scss';
import Microphone_BLACK from '@/assets/icons/Microphone_black.svg';
import Microphone_RED from '@/assets/icons/Microphone_red.svg';
import { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { TranslateService } from '@/services/translate.service';
import { DotLoader } from 'react-spinner-overlay'

function Text2SignLang(props: {display: boolean}) {
  const { transcript, listening: isListening, resetTranscript } = useSpeechRecognition();
  const [ textAreaValue, setTextAreaValue ] = useState('');
  const [ isUnityLoaded, setIsUnityLoaded ] = useState(false);

  const { unityProvider, sendMessage, isLoaded } = useUnityContext({
    loaderUrl: 'Unity/KSLTAvatar.loader.js',
    dataUrl: 'Unity/KSLTAvatar.data',
    frameworkUrl: 'Unity/KSLTAvatar.framework.js',
    codeUrl: 'Unity/KSLTAvatar.wasm',
    companyName: 'KSLT',
    productName: 'KSLT',
    productVersion: '1.0.0',
  });

  useEffect(() => {setTextAreaValue(transcript)}, [transcript]);
  useEffect(() => {
    // 커스텀 로딩화면 시작
    setTimeout(() => setIsUnityLoaded(true), 3500);

    // 화면크기 변환 시 카메라 줌 기능 설정
    const handleResize = () => sendMessage('Main Camera', window.innerWidth < 1080 ? 'Zoom_Out' : 'Zoom_In');
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isLoaded]);

  return (
    <div className={`text-to-sign-lang ${props.display ? '' : 'text-to-sign-lang--hidden'}`}>
      <div className="text-to-sign-lang__input input">
      <textarea
        className="input__text-field" 
        placeholder="번역할 음성을 녹음해주세요." 
        spellCheck="false"
        autoComplete="false" 
        value={textAreaValue}
        onChange={({target: {value}}) => setTextAreaValue(value)} 
        disabled = {isListening}
      />
        <img
          className="input__microphone-button"
          src={!isListening ? Microphone_BLACK : Microphone_RED}
          alt="마이크"
          onClick={() => {
            if(!isListening) {
              resetTranscript();
              SpeechRecognition.startListening({continuous: true, language: 'ko'});
            } else {
              SpeechRecognition.stopListening();
            }
          }}
        />
        <button
          className='input__translate-btn'
          onClick={async() => {
            const translateFunctionName = await TranslateService.getInstance().text2sign(textAreaValue);
            sendMessage('harry', 'Act', JSON.stringify(translateFunctionName));
          }}
        >
          번<br/>역
        </button>
      </div>
      <div className="text-to-sign-lang__output output">
        <div className={`output__loading ${isUnityLoaded ? 'output__loading--hidden' : ''}`}>
            <DotLoader
              loading={!isUnityLoaded}
              size={25}
              color='orange'
            />
        </div>
        <Unity unityProvider={unityProvider} className='output__avatar' />
      </div>
    </div>
  );
}

export default Text2SignLang;