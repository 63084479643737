import { useEffect, useState } from 'react';

type Type = 'mobile' | 'pc';

function AdsBox(props: {adsImage: string, type: Type, width: string, height: string}) {
    const checkCurrentType: () => Type = () => window.innerWidth < 1080 ? 'mobile' : 'pc';
    const [currentType, setCurrentType] = useState<Type>(checkCurrentType());
    const isActiveAds = localStorage.getItem('ads') === 'true';

    useEffect(() => {
        const handleResize = () => setCurrentType(checkCurrentType());
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div 
            className='ads-box'
            style={{
                width: props.width,
                height: props.height,
                display: isActiveAds && currentType === props.type ? 'block' : 'none'
            }}
        >
            <img
                src={props.adsImage}
                alt='광고'
                style={{width: props.width, height: props.height}}
            />
        </div>
    );
}

export default AdsBox;