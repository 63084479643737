import Header from './components/header/header';
import SignLang2Text from './components/signLang2Text/signLang2Text';
import Text2SignLang from './components/text2SignLang/text2SignLang';
import AdsBox from './components/adsBox/adsBox';
import './main.scss';
import { useAppSelector } from './module/hooks';
// FIXME: 이미지 경로 수정
import PCAds from '@/assets/images/pc-ads.jpg';
import MobileAds from '@/assets/images/mobile-ads.jpg';

function Main() {
  const translateMode = useAppSelector(state => state.translateModeSetter.value);

  return (
    <div className="main">
      <Header />
      <AdsBox adsImage={MobileAds} type="mobile" width='100%' height='100px' />
      <div className="main__section">
        <AdsBox adsImage={PCAds} type="pc" width='100px' height='100%' />
        <Text2SignLang display={translateMode === 'text2SignLang'} />
        <SignLang2Text display={translateMode === 'signLang2Text'} />
        <AdsBox adsImage={PCAds} type="pc" width='100px' height='100%' />
      </div>
    </div>
  );
}

export default Main;
