import axios, { Axios } from 'axios';

export class TranslateService {
    private static instance: TranslateService;
    private axios: Axios;

    static getInstance() {
        return this.instance || (this.instance = new this());
    }
    
    private constructor() {
        const url = process.env.NODE_ENV === 'development' ? 'http://localhost/api' : 'https://kslt.p-e.kr/api';
        this.axios = axios.create({baseURL: url});
    }

    async text2sign(transcriptValue: string) {
        const response = await this.axios.get('/text2sign', {params: {text: transcriptValue}});
        return response.data;
    }

    async sign2text(signLanguageVideoValue: Blob) {
        const formData = new FormData();
        formData.append('video', signLanguageVideoValue);
        
        const response = await this.axios.post('/sign2text', formData);
        return response.data;
    }

    async text2voice(synthesisValue: string, gender: string) {
        const response = await this.axios.get('/text2voice', {params: {text: synthesisValue, gender}});
        return response.data;
    }
}