import DefaultProfile from '@/assets/icons/DefaultProfile.svg';
import RecordStart from '@/assets/icons/RecordStart.svg';
import RecordStop from '@/assets/icons/RecordStop.svg';
import Voice from '@/assets/icons/Voice.svg';
import { useAppSelector } from '@/module/hooks';
import { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import GenderToggle from '../genderToggle/genderToggle';
import './signLang2Text.scss';
import { TranslateService } from '@/services/translate.service';
import 'react-notifications/lib/notifications.css';
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';

function SignLang2Text(props: {display: boolean}) {
  const [textAreaValue, setTextAreaValue] = useState('');
  const webcamRef = useRef<Webcam>(null);
  const voiceGender = useAppSelector(state => state.genderSetter.voice);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recordedChunks, setRecordedChunks] = useState<Array<Blob>>([]);
  const [capturing, setCapturing] = useState(false);

  useEffect(() => {
    (async () => {
      if(!capturing) {
        if (recordedChunks.length) {
          const blob = new Blob(recordedChunks, {type: 'video/webm'});
          setRecordedChunks([]);

          const translateText = await TranslateService.getInstance().sign2text(blob);
          
          if(!translateText) {
            NotificationManager.error('손이 인식되지 않았습니다. \n다시 시도해주세요', '인식오류');
          }

          setTextAreaValue(translateText);
        }
      }
    })();
  }, [recordedChunks]);

  return (
    <div className={`sign-lang-to-text ${props.display ? '' : 'sign-lang-to-text--hidden'}`}>
      <div className="sign-lang-to-text__input input">
        <Webcam className="input__webcam" imageSmoothing={true} ref={webcamRef} style={{display: capturing ? 'block' : 'none'}} />
        {!capturing && <img className="input__webcam input__webcam--disabled" src={DefaultProfile} alt="프로필사진" />}
        <img
          className="input__recording-indicator"
          src={capturing ? RecordStop : RecordStart}
          alt={capturing ? '녹화 중지': '녹화 시작'}
          onClick={() => {
            if(capturing) {
              if(mediaRecorderRef.current) {
                mediaRecorderRef.current.stop();
                setCapturing(false);
              }
            } else {
              if(webcamRef.current && webcamRef.current.stream) {
                setCapturing(true);
                mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream as MediaStream, {mimeType: "video/webm"});
                mediaRecorderRef.current.addEventListener(
                  "dataavailable",
                  ({ data }) => {
                    if (data.size > 0) {
                      setRecordedChunks((prev) => prev.concat(data));
                    }
                  },
                );
                mediaRecorderRef.current.start();
              }
              setTextAreaValue('번역 중...');
            }
          }}
        />
      </div>
      <NotificationContainer/>
      <div className="sign-lang-to-text__output output">
        <textarea className="output__text-field" value={textAreaValue} spellCheck="false" autoComplete="false" onChange={({target: {value}}) => setTextAreaValue(value)}  />
        <div className="output__voice-box">
          <img
            className="output__voice-button"
            src={Voice}
            alt="음성"
            onClick={async () => {
              const upperVoiceGender = voiceGender.toUpperCase();
              const translateBase64VoiceValue = await TranslateService.getInstance().text2voice(textAreaValue, upperVoiceGender);
              
              const audioFile = new Audio();
              audioFile.src = translateBase64VoiceValue;
              audioFile.play();
            }}
          />
          <GenderToggle type="voice" />
        </div>
      </div>
    </div>
  );
}

export default SignLang2Text;