import TranslateModeToggle from '../translateModeToggle/translateModeToggle';
import './header.scss';

function Header() {
  return (
    <div className="header">
      <div className="header__logo">
      <span className='header__size'>KSLT</span>
      <br />
      <span className='header__bold_font'>Korean</span> <span className='header__light_font'>Sign</span> <span className='header__bold_font'>Langauge</span> <span className='header__light_font'>Translator</span>
      </div>
      <TranslateModeToggle />
    </div>
  );
}

export default Header;